<template>
  <span>
    <button @mouseover="updateTippy" @click="updateTippy" :data-tippy-content="explanationNoHtml" :aria-label="$t('whyWeNeedTheseData')" type="button" class="popoverButton"><font-awesome-icon icon="question-circle" class="mx-1 text-gray-800"/></button>
  </span>
</template>

<script>
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css'; // optional for styling

export default {
  props: ['explanation'],
  data() {
    return {
      explanationNoHtml: null
    }
  },
  methods:
  {
    // should be called at mouseover, to make sure language-related changes are applied
    updateTippy()
    {
      let stringTranslated = this.$i18n.t(this.explanation)
      this.explanationNoHtml = this.stripHtml(stringTranslated);
      tippy('[data-tippy-content]');
    },
    stripHtml(html)
    {
      let tmp = document.createElement("DIV");
      tmp.innerHTML = html;
      return tmp.textContent || tmp.innerText || "";
    }
  }
}
</script>
